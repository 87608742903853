@mixin svgFill($color, $size: false) {
  svg {
    @if $size {
      width: $size;
      height: $size;
    }

    path {
      transition: all 0.5s;
      fill: $color;
      @content;
    }
  }
}

@mixin svgSize($size, $minMaxSize: false) {
  svg {
    width: $size;
    height: $size;

    @if $minMaxSize {
      min-width: $minMaxSize;
      min-height: $minMaxSize;
      max-width: $minMaxSize;
      max-height: $minMaxSize;
    }
  }
}

@mixin scrollbar($width) {
  &::-webkit-scrollbar {
    width: $width;

    @content;
  }
}

@mixin scrollbarHorizontal($height) {
  &::-webkit-scrollbar {
    height: $height;

    @content;
  }
}

@mixin cardsWrapper() {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 285px);
  //align-content: center;
  justify-content: center;
  row-gap: 12px;
  column-gap: 8px;

  @media (max-width: $wide) {
    grid-template-columns: repeat(3, 285px);
  }

  @media (max-width: $tablet-l) {
    grid-template-columns: repeat(2, 285px);
  }

  @media (max-width: $tablet-w) {
    grid-template-columns: repeat(2, 285px);
  }

  @media (max-width: $tablet) {
    grid-template-columns: repeat(2, 244px);
  }

  @media (max-width: 532px) {
    grid-template-columns: repeat(2, 200px);
  }
  @media (max-width: 456px) {
    grid-template-columns: repeat(2, 198px);
  }
  @media (max-width: 424px) {
    grid-template-columns: repeat(2, 188px);
  }
  @media (max-width: 414px) {
    grid-template-columns: repeat(2, 178px);
  }
  @media (max-width: 394px) {
  }
  @media (max-width: 364px) {
    column-gap: 10px;
  }
}
