$white: #fff;
$white-100: #f5f5f5;

$red: #ab0000;
$red-2: #ff1520;
$yellow: #ffc107;

$black: #000;
$black-100: #1a1a1a;
$black-200: #21201e;
$black-300: #4c5563;
$black-400: #959595;
$black-500: #323232;
$black-600: #091524;

//colors with opacity

$opacity-1: rgba(26, 26, 26, 0.2);
$opacity-2: rgba(255, 255, 255, 0.1);
$opacity-3: rgba(149, 149, 149, 0.2);
$opacity-4: rgba(26, 26, 26, 0.1);
