@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  height: 100%;
  max-width: 285px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px rgba(26, 26, 26, 0.1);
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  @media (max-width: $tablet-w) {
    // max-width: 192px;
  }

  &:hover {
    scale: 1.005;
  }

  &__check {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  &__like {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;

    @media (max-width: $tablet-w) {
      position: static;
    }
  }

  &__image {
    width: 100%;
    height: 29.25vw;
    border-radius: 10px 10px 0px 0px;
    object-fit: cover;
    overflow: hidden;
    transition: all;
    margin: 0;
    @media (max-width: 976px) {
      height: 41.25vw;
    }
    @media (max-width: 600px) {
      height: 56.25vw;
    }
  }

  &__description {
    padding: 16px;
    border-radius: 0px 0px 10px 10px;
    gap: 8px;
    @extend %flex-column;
  }

  &__prom {
    color: $white;
    padding: 4px 8px;
    background-color: #ff0000;
    border-radius: 2px;
    width: max-content;
    @extend %micro-1;
  }
  &__new {
    color: $white;
    padding: 4px 8px;
    background-color: #00a3ff;
    border-radius: 2px;
    width: max-content;
    @extend %micro-1;
  }

  &__title {
    color: $black-100;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 253px;
    overflow: hidden;
    @extend %secondary-1;

    @media (max-width: $tablet-w) {
      font-size: 14px;
    }
  }

  &__rating {
    gap: 12px;
    @extend %flex-align;

    @media (max-width: $tablet-w) {
      @include svgSize(10px);
      gap: 3px;
    }
  }

  &__reviewers {
    color: $black-300;
    @extend %micro-2;

    @media (max-width: $tablet-w) {
      font-size: 10px;
    }
  }

  &__price {
    color: $black-100;
    @extend %subheading-1;

    @media (max-width: $tablet-w) {
      font-size: 16px;
    }

    &__promotion {
      color: $red;
      @extend %subheading-1;
    }

    &__old {
      color: $black-300;
      @extend %micro-3;
      text-decoration: line-through;
    }
  }

  &__buy {
    @extend %flex-align;
    gap: 10px;

    &__button {
      width: 118px;
      height: 34px;
      padding: 10px;
      font-size: 14px;
    }
  }
}
