@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  padding: 16px 60px;
  color: $white;
  background-color: $red;
  border-radius: 6px;
  gap: 10px;
  justify-content: center;
  @extend %flex-align;
  @extend %secondary-1;
  @include svgFill($white);

  &__transparent {
    color: $red;
    border: 1px solid $red;
    background-color: transparent;
    @include svgFill($red);
  }

  @media (max-width: $tablet-w) {
    width: 100%;
    padding: 16px;
  }
}
