// media queries vars
$mobile-m: 375px;
$mobile-w: 420px;
$tablet-m: 576px;
$tablet: 665px;
$tablet-w: 876px;
$tablet-l: 992px;
$desktop: 1024px;
$wide-s: 1096px;
$wide: 1256px;
$wide-m: 1366px;
$wide-l: 1440px;

// @extends
%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-align {
  display: flex;
  align-items: center;
}

%flex-stretch {
  display: flex;
  align-items: stretch;
}

%flex-align-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flex-column-align {
  display: flex;
  align-items: center;
  flex-direction: column;
}

%flex-justify-center {
  display: flex;
  justify-content: center;
}

%flex-justify-between-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-justify-between {
  display: flex;
  justify-content: space-between;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

%flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%flex-column-center-reverse {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}

%flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

%flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%flex-column-between-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

%flex-align-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-align-evenly {
  display: flex;
  align-items: center;
  justify-content: sevenly;
}

%flex-column-align-between {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

%flex-end-between {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

%flex-end {
  display: flex;
  justify-content: flex-end;
}

%flex-align-end {
  display: flex;
  justify-content: flex-end;
}

%flex-start {
  display: flex;
  justify-content: flex-end;
}

%flex-column-align-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

%flex-column-align-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

%flex-align-start {
  display: flex;
  align-items: flex-start;
}

%flex-justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

%flex-justify-evenly-center {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

%flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

%flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

%flex-align-center-row-reverse {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

%flex-justify-start-align {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
