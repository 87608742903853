@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
  }

  &__container {
    width: 100%;
    padding: 72px 120px;
    gap: 42px;
    @extend %flex-column;

    @media (max-width: $tablet-w) {
      padding: 36px 60px;
      gap: 21px;
    }

    @media (max-width: $mobile-w) {
      padding: 15px 30px;
      gap: 10px;
    }

    &__button {
      position: relative;
      width: 176px;
      height: 38px;
      cursor: pointer;
      @extend %flex-center;

      @media (max-width: $tablet-w) {
        width: 100px;
        height: 24px;
      }

      @media (max-width: $mobile-w) {
        width: 60px;
        height: 12px;
      }

      &__bg {
        background-color: $red;
        transform: skew(15deg, 15deg) rotate(345deg);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
      }

      &__text {
        color: $white;
        @extend %secondary-2;

        @media (max-width: $tablet-w) {
          font-size: 12px;
        }

        @media (max-width: $mobile-w) {
          font-size: 6px;
        }
      }
    }

    &__description {
      gap: 12px;
      @extend %flex-column;

      @media (max-width: $tablet-w) {
        gap: 8px;
      }

      @media (max-width: $mobile-w) {
        gap: 3px;
      }
    }

    &__title {
      @extend %heading-1;
      color: $red-2;

      @media (max-width: $tablet-w) {
        font-size: 32px;
      }

      @media (max-width: $mobile-w) {
        font-size: 26px;
      }
    }

    &__subtitle {
      color: $black-500;
      @extend %heading-4;

      @media (max-width: $tablet-w) {
        font-size: 20px;
        font-weight: 400;
      }

      @media (max-width: $mobile-w) {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &__text {
      color: $black-500;
      @extend %secondary-1;

      @media (max-width: $mobile-w) {
        font-size: 8px;
      }
    }

    &__footer {
      gap: 24px;
      @extend %flex-column;

      @media (max-width: $tablet-w) {
        gap: 16px;
      }

      @media (max-width: $mobile-w) {
        gap: 10px;
      }

      &__about {
        gap: 120px;
        @extend %flex-align;

        @media (max-width: $tablet-w) {
          gap: 60px;
        }

        @media (max-width: $mobile-w) {
          gap: 16px;
        }
      }

      &__item {
        gap: 10px;
        @extend %flex-align;

        @media (max-width: $mobile-w) {
          gap: 3px;
        }
      }

      &__icon {
        background-color: $red;
        width: 28px;
        height: 28px;
        @extend %flex-center;
        @include svgSize(16px, 16px);

        @media (max-width: $mobile-w) {
          width: 12px;
          height: 12px;
          @include svgSize(8px, 8px);
        }
      }
    }
  }
}
