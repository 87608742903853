@import 'styles/vars';
@import 'styles/typography';
@import 'styles/colors';

.wrapper {
  width: 100%;
  min-width: 440px;
  padding: 24px;
  border-radius: 8px;
  background-color: #9595951a;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__prices {
    div {
      width: 100%;
      gap: 16px;
      margin-top: 16px;
      font-size: 14px;
      @extend %flex-justify-between;

      span {
        font-weight: 700;
      }
    }

    &__footer {
      padding-top: 16px;
      border-top: 1px solid #95959533;

      font-size: 16px;

      p {
        font-size: 16px;
        font-weight: 500;
      }

      span {
        font-size: 16px;

        font-weight: 700;
        color: #ab0000;
      }

      @media (max-width: $tablet-l) {
        border: none;
      }
    }
  }

  &__buttons {
    a {
      width: 100%;
      @media (max-width: $tablet-l) {
        width: 100%;
      }
    }

    &__btn_1 {
      width: 100%;
    }

    &__btn_2 {
      margin-top: 10px;
      width: 100%;
      color: #ab0000;
      background: transparent;
      border: 1px solid #ab0000;
    }
  }

  @media (max-width: $tablet-l) {
    background: white;
    width: 100%;
    padding: 0;
  }
  @media (max-width: 500px) {
    min-width: 0px;
  }
}

.modal__login__confirm {
  min-width: 412px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    font-weight: 400;
    margin: auto;
    text-align: center;
    @extend %micro-3;

    b {
      font-weight: 700;
    }
  }

  span {
    font-weight: 700;
    font-size: 65px;
    line-height: 100%;
    text-align: center;
    color: $red;
    @extend %flex-justify-center;
  }

  &__buttons {
    gap: 10px;
    @extend %flex-justify-between;

    button {
      padding: 16px 32px;
    }

    &__btn1 {
      background: $red;
      color: $white;
      border: 2px solid $red;
      width: 100%;
    }
  }
}
