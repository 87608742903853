@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 24px 0;
  gap: 32px;
  @extend %flex-column;

  @media (max-width: $tablet-w) {
    padding: 48px 15px 0;
  }

  &__container {
    gap: 40px;
    @extend %flex-align;

    &__image > img {
      object-fit: contain;
    }

    &__description {
      gap: 32px;
      @extend %flex-column;

      &__title {
        color: $black-200;
        @extend %heading-3;

        @media (max-width: $tablet-w) {
          font-size: 26px;
          font-weight: 700;
        }
      }

      &__subtitle {
        color: $black-300;
        @extend %secondary-1;
      }
    }
  }

  &__footer {
    gap: 40px;
    @extend %flex-align;

    @media (max-width: $tablet-w) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      column-gap: 9px;
    }

    &__item {
      @extend %flex-column;
      width: 100%;
      max-width: 270px;

      &__title {
        color: $red;
        @extend %heading-2;

        @media (max-width: $tablet-w) {
          font-size: 41px;
          font-weight: 600;
        }
      }

      &__subtitle {
        padding-top: 12px;
        border-top: 1px solid $opacity-1;
        color: $black-300;
        width: 100%;
        @extend %micro-3;

        @media (max-width: $tablet-w) {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}
