@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {
  width: 100%;
  padding: 120px 24px 0;
  gap: 40px;
  @extend %flex-center;

  @media (max-width: $tablet-w) {
    padding: 48px 15px;
    flex-direction: column;
  }

  &__image img {
    border-radius: 16px;
    object-fit: contain;

    @media (max-width: $tablet-w) {
      width: 100%;
      height: 100%;
    }
  }

  &__description {
    gap: 32px;
    width: 100%;
    max-width: 464px;
    @extend %flex-column;

    @media (max-width: $tablet-w) {
      max-width: unset;
      gap: 12px;
    }

    &__title {
      color: $black-100;
      @extend %heading-3;
    }

    &__stars {
      margin-top: -20px;

      @media (max-width: $tablet-w) {
        margin-top: 0;

        @include svgFill($red, 24px);
      }
    }

    &__price {
      color: $black-100;
      @extend %subheading-3;
    }

    &__text {
      color: $black-300;
      @extend %secondary-1;
    }

    &__about {
      width: 100%;
      gap: 12px;
      @extend %flex-justify-between;

      @media (max-width: $tablet-w) {
        flex-wrap: wrap;
      }

      > div {
        gap: 12px;
        @extend %flex-column;
      }

      &__item {
        gap: 12px;
        @extend %flex-align;

        &__text {
          color: $black;
          @extend %secondary-1;
        }

        svg {
          border-radius: 50%;
        }
      }
    }
  }
}
