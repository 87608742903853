.modal {
  position: fixed;
  width: 100%;
  min-height: 100vh; 
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(26, 26, 26, 0.2); 
  display: flex;
  align-items: center; 
  justify-content: center;
  padding: 20px 0; 

  &__block {
    padding: 20px;
    width: 100%;
    max-width: 600px;
    background: white;
    border-radius: 12px;
    position: relative;
    overflow-y: auto; 
    max-height: calc(100vh - 120px); 
    margin: 0 15px;

    &__header {
      padding: 32px;
      width: 100%;
      border-bottom: 2px solid rgba(149, 149, 149, 0.2); 
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__close__btn {
        width: 24px;
        height: 24px;
      }
    }

    &__title {
      font-size: 26px;
      line-height: 100%;
      font-weight: 700;
    }
  }
}
