@import 'styles/vars';
@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/typography';

.wrapper {

  &__container {
    width: 100%;
  }

  &__catalog {
    padding: 14px 24px;
  }

  &__icons_group {
    gap: 32px;
    @extend %flex-align;

    &__item {
      position: relative;
      gap: 8px;
      color: $black-400;
      text-align: center;
      cursor: pointer;
      transition: all 0.6s;
      @extend %flex-column-center;
      @extend %secondary-1;

      &__active {
        position: relative;

        color: $white;
        @include svgFill($white);
      }
    }
  }
}

.modal__form {
  padding: 32px;
  width: 100%;
  gap: 20px;
  @extend %flex-column;

  button {
    width: 100%;
  }

  &__link {
    text-align: center;
    color: $red;
    font-weight: 300;
    @extend %micro-3, %flex-justify-center;
  }

  &__frame {
    max-width: 387px;
    @extend %micro-2;

    b {
      color: $red;
    }
  }
  @media (max-width:$tablet) {
    padding: 18px 0;
  }
}

.modal__login__confirm {
  min-width: 412px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    font-weight: 400;
    max-width: 322px;
    margin: auto;
    text-align: center;
    @extend %micro-3;

    b {
      font-weight: 700;
    }
  }

  span {
    font-weight: 700;
    font-size: 65px;
    line-height: 100%;
    text-align: center;
    color: $red;
    @extend %flex-justify-center;
  }

  &__buttons {
    gap: 10px;
    @extend %flex-justify-between;
    @media (max-width:$tablet) {
      flex-direction: column;
    }

    button {
      padding: 16px 32px;
    }

    &__btn2 {
      background: $white;
      color: $red;
      border: 2px solid $red;
    }
  }
  @media (max-width:$tablet) {
    width: 100%;
    min-width: 0px;
      padding: 18px 0px;
    

  }
}


