p,
a,
q,
s,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
tr,
th,
td,
em,
h1,
h2,
h3,
h4,
h5,
h6,
tt,
sub,
sup,
var,
nav,
pre,
del,
dfn,
img,
ins,
kbd,
div,
big,
span,
form,
menu,
ruby,
html,
body,
cite,
abbr,
code,
samp,
time,
mark,
audio,
video,
label,
tbody,
tfoot,
thead,
aside,
embed,
small,
table,
canvas,
legend,
strike,
strong,
figure,
center,
applet,
object,
iframe,
footer,
header,
hgroup,
output,
caption,
details,
article,
section,
summary,
acronym,
address,
fieldset,
figcaption,
blockquote {
  border: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

nav,
menu,
aside,
figure,
footer,
header,
hgroup,
details,
article,
section,
figcaption {
  display: block;
}

ol,
ul {
  list-style: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

input {
  font-size: 100%;
  box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  outline: none;

  &[type='text'],
  &[type='number'],
  &[type='tel'],
  &[type='phone'],
  &[type='password'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 1000s;
    -webkit-text-fill-color: #fff;
    caret-color: #fff;
  }

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

a,
button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

svg {
  cursor: pointer;
}

html {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  padding: 0;
  width: 100%;
  height: 100%;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  background-attachment: fixed;
  font-family: 'Nunito';
  min-height: -webkit-fill-available;
}
