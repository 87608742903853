@import 'styles/vars';
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  gap: 32px;
  
  padding: 48px 24px 0;
  @extend %flex-column-center;

  @media (max-width: $tablet-w) {
    padding: 48px 15px 0;
  }

  &__content {
    gap: 25px;
    flex-wrap: wrap;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: $wide) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $tablet-l) {
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
      max-width: 828px;
      width: 100%;
      @include scrollbarHorizontal(0);
    }
  }

  &__item {
    gap: 10px;
    cursor: pointer;
    @extend %flex-column-align-center;

    &__image_container {
      width: 285px;
      height: 285px;
      background-color: $white;
      border-radius: 10px;
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));

      img {
        object-fit: contain;
      }
    }
  }

  &__title {
    color: $black-100;
    white-space: nowrap;
    width: 150px;
    text-overflow: ellipsis;
    @extend %secondary-1;
    @extend %flex-center;
  }
}
