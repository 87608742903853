@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

.wrapper {
  width: 100%;
  text-align: center;
  gap: 12px;
  @extend %flex-column-center;
  padding: 15px;

  &__title_container {
    gap: 12px;
    @extend %flex-align;
  }

  &__line {
    width: 32px;
    height: 4px;
    background-color: $red;
    border-radius: 20px;
  }

  &__title {
    color: $black-100;
    @extend %heading-3;

    @media (max-width: $mobile-w) {
      font-size: 26px;
    }
  }

  &__subtitle {
    color: $black-300;
    @extend %secondary-1;
  }
}
