// Local css base
@import './lib.scss';
@import './vars.scss';
@import './fonts.scss';
@import './local.scss';
@import './colors.scss';
@import './mixins.scss';
@import './typography.scss';


@tailwind base;
@tailwind components;
@tailwind utilities;



.list_quality{
    position: absolute;
    background-color: $red;
    border-radius: 100%;
    width: 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    right: 0;
    color: white;
    top: -9px;
  }

  .slidenavbar-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .slidenavbar-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .slidenavbar-exit {
    opacity: 1;
  }
  .slidenavbar-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms, transform 300ms;
  }
  
  .border-input{
    border: 1px solid #00000033;
    border-radius: 10px;
  }
  
  .popup-enter {
    opacity: 0;
    transform: scale(0.8); /* Start with smaller size */
  }
  .popup-enter-active {
    opacity: 1;
    transform: scale(1); /* End with normal size */
    transition: opacity 300ms, transform 300ms;
  }
  .popup-exit {
    opacity: 1;
  }
  .popup-exit-active {
    opacity: 0;
    transform: scale(0.8); /* Shrink to smaller size */
    transition: opacity 300ms, transform 300ms;
  }
  

  .option-product-card{
    border: 1px solid #00000033;
  }

  .OrderedItemBlock{
    display: flex;
    flex-direction: row;
    width: '100%';
    justify-content: space-between;
    align-items: center;
}

.radio_input{
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  padding: 3px;
  border: 1px solid #ab0000;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}
.custom-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@layer components {
  .center {
    @apply flex justify-center items-center
  }
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 6px;
  border: 3px solid #ffffff;
}