@import 'styles/vars';
@import 'styles/colors';
@import 'styles/typography';

$bgPath: '../../../assets/images/bg.png';

.wrapper {
  width: 100%;
  background: url($bgPath) no-repeat;
  height: 510px;
  background-size: cover;
  gap: 32px;
  margin-top: 120px;
  @extend %flex-column-center;
  text-align: center;

  @media (max-width: $tablet-w) {
    gap: 8px;
    height: 583px;
    margin-top: 48px;
  }

  &__title {
    color: $white;
    @extend %heading-2;

    @media (max-width: $tablet-w) {
      font-size: 26px;
      margin-top: 24px;
    }
  }

  &__subtitle {
    color: $black-400;
    @extend %subheading-2;

    @media (max-width: $tablet-w) {
      font-size: 26px;
      font-weight: 300;
    }
  }
}
