%secondary-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
}

%secondary-2 {
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

%secondary-3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
}

%heading-1 {
  font-size: 110px;
  font-weight: 800;
  line-height: 100%;
}

%heading-2 {
  font-size: 65px;
  font-weight: 600;
  line-height: 100%;
}

%heading-3 {
  font-size: 41px;
  font-weight: 700;
  line-height: 100%;
}

%heading-4 {
  font-size: 48px;
  font-weight: 400;
  line-height: 100%;
}

%heading-5 {
  font-size: 41px;
  font-weight: 600;
  line-height: 100%;
}

%subheading-1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
}

%subheading-2 {
  font-size: 26px;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: -0.26px;
}

%subheading-3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 100%;
}

%subheading-4 {
  font-size: 26px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.26px;
}

%micro-1 {
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
}

%micro-2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}

%micro-3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
}

%micro-4 {
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
}

%micro-5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
}
